import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import IntroCards from 'components/1080p_Series_CGI_List/Alarm_Menu/alarmCGI';
import NavButtons from 'components/1080p_Series_CGI_List/NavButtons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='1080p Serie CGI List :: Alarm Menu' dateChanged='2019-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='1080p Serie CGI List :: Alarm Menu' image='/images/Search/AU_SearchThumb_CGICommands_1080p.png' twitter='/images/Search/AU_SearchThumb_CGICommands_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/1080p_Serie_CGI_Befehle/Alarm_Menu/' locationFR='/fr/1080p_Series_CGI_List/Alarm_Menu/' crumbLabel="Alarm" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <IntroCards mdxType="IntroCards" />
    {/* * <Link to="/1080p_Serie_CGI_Befehle/Alarm_Menu/Actions/">Actions</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Alarm_Menu/Alarm_Server/">Alarm Server</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Alarm_Menu/Areas/">Areas</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Alarm_Menu/Push_Service/">Push Service</Link>
     * <Link to="/1080p_Serie_CGI_Befehle/Alarm_Menu/Schedule/">Schedule</Link> */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      